<template>
  <nav class="sticky top-0 z-20 bg-white shadow-sm">
    <div 
      class="relative mx-auto grid h-14 max-w-screen-md grid-cols-5 items-center text-center"
    >
      <a 
        href="#description"
        class="text-sm font-thin hover:underline md:text-base"
      >
        Om oss
      </a>
      
      <a href="#news" class="text-sm font-thin hover:underline md:text-base">
        Nyheter
      </a>
      
      <a href="#dogs" class="text-sm font-thin hover:underline md:text-base">
        Hundar
      </a>
      
      <a href="#ads" class="text-sm font-thin hover:underline md:text-base">
        Annonser
      </a>
      
      <a href="#gallery" class="text-sm font-thin hover:underline md:text-base">
        Galleri
      </a>
      
      <div
        class="absolute bottom-0 h-1 bg-green-600 transition-transform" 
        :style="{ width: 'calc(20%)', transform: `translateX(${currentSection * 100}%)` }"
      />
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Kennel } from "@/@types";
import { debounce } from "lodash-es";

export default defineComponent({
  data() {
    return {
      currentSection: 0,
      sectionBreakpoints: [],
    }
  },
  computed: {
    kennel() {
      return this.$page.props.kennel as Kennel;
    },
  },
  mounted() {
    document.querySelectorAll('section[id]')
      .forEach(el => {
        this.sectionBreakpoints.push(el.offsetTop - 16 * 4);
      })

    document.addEventListener('scroll', debounce(this.onScroll.bind(this), 25))
  },
  methods: {
    onScroll() {
      this.currentSection = 0;
      
      this.sectionBreakpoints.forEach((breakPoint, n) => {
        if (window.scrollY >= breakPoint) {
          this.currentSection = n;
        }
      })
    },
  },
});
</script>
